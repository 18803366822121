import { Rule } from 'antd/lib/form';
import { mbTrim } from 'lib/utils';
import {
    HolidayType,
    idling_time_validator,
    regExpPasswordCode,
    regExpSymbol,
    regExpUrl,
    regUrlTwoByte,
    FUTURE_DATE_EXCEPTION,
} from 'config/constant';
import { regExpDeviceCode } from 'config/constant';
import { NamePath } from 'antd/lib/form/interface';

export const maxValidator: (max: number, message: string) => Rule = (max, message) => {
    return () => ({
        validator(_, value) {
            const trimValue = mbTrim(value).replace(/[\n\r]/g, '');
            if (value && trimValue.length > max) {
                return Promise.reject(new Error(message));
            }
            return Promise.resolve();
        },
    });
};

export const minValidator: (min: number, message: string) => Rule = (min, message) => {
    return () => ({
        validator(_, value) {
            const trimValue = mbTrim(value).replace(/\n/g, '');
            if (value && trimValue.length < min) {
                return Promise.reject(new Error(message));
            }
            return Promise.resolve();
        },
    });
};

export const numberFloatValidator: (maxValue: number, message: string) => Rule = (maxValue, message) => {
    return () => ({
        validator(_, value) {
            if (maxValue > 9999) {
                return Promise.reject(new Error(message));
            }
            return Promise.resolve();
        },
    });
};

export const formatZipCodeValidator: (max: number, message_1: string, message_2: string, message_3: string) => Rule = (
    max,
    message_1,
    message_2,
    message_3
) => {
    return () => ({
        validator(_, value) {
            if (value && mbTrim(value).length === 0) {
                return Promise.reject(new Error(message_3));
            } else if (value && (isNaN(mbTrim(value)) || value.toString().search(/[+-]/) === 0 || value.toString().includes('.'))) {
                return Promise.reject(new Error(message_1));
            } else if (value && mbTrim(value).length > max) {
                return Promise.reject(new Error(message_2));
            } else if (value && mbTrim(value).length < max) {
                return Promise.reject(new Error(message_1));
            }
            return Promise.resolve();
        },
    });
};

export const formatLatitudeValidator: (message_1: string, message_2: string) => Rule = (message_1, message_2) => {
    return () => ({
        validator(_, value) {
            if (value) {
                value = value.toString().replace(',', '.');
            }
            if (value && mbTrim(value).length === 0) {
                return Promise.reject(new Error(message_2));
            } else if (value && (isNaN(mbTrim(value)) || mbTrim(value).toString() === '-0')) {
                return Promise.reject(new Error(message_1));
            } else if (value && (Number(mbTrim(value)) < -90 || Number(mbTrim(value)) > 90)) {
                return Promise.reject(new Error(message_1));
            }
            return Promise.resolve();
        },
    });
};

export const formatLongitudeValidator: (message_1: string, message_2: string) => Rule = (message_1, message_2) => {
    return () => ({
        validator(_, value) {
            if (value) {
                value = value.toString().replace(',', '.');
            }
            if (value && mbTrim(value).length === 0) {
                return Promise.reject(new Error(message_2));
            } else if (value && (isNaN(mbTrim(value)) || mbTrim(value).toString() === '-0')) {
                return Promise.reject(new Error(message_1));
            } else if (value && (Number(mbTrim(value)) < -180 || Number(mbTrim(value)) > 180)) {
                return Promise.reject(new Error(message_1));
            }
            return Promise.resolve();
        },
    });
};

export const formatNumberValidator: (
    maxValue: number,
    message_1: string,
    message_2: string,
    message_3: string,
    message_4: string
) => Rule = (maxValue, message_1, message_2, message_3, message_4) => {
    return () => ({
        validator(_, value) {
            if (value) {
                value = value.toString().replace(',', '.');
            }
            if (value && mbTrim(value).length === 0) {
                return Promise.reject(new Error(message_1));
            } else if (value && (isNaN(mbTrim(value)) || mbTrim(value).toString() === '-0')) {
                return Promise.reject(new Error(message_2));
            } else if (maxValue > 0 && value && Number(mbTrim(value)) > maxValue) {
                return Promise.reject(new Error(message_3));
            } else if (value && Number(mbTrim(value)) < 0) {
                return Promise.reject(new Error(message_4));
            }
            return Promise.resolve();
        },
    });
};

// export const formatNumberValidator: (
//     minValue: number,
//     message_1: string,
//     message_2: string,
//     message_3: string,
//     message_4: string
// ) => Rule = (minValue, message_1, message_2, message_3, message_4) => {
//     return () => ({
//         validator(_, value) {
//             if (value) {
//                 value = value.toString().replace(',', '.');
//             }
//             if (value && mbTrim(value).length === 0) {
//                 return Promise.reject(new Error(message_1));
//             } else if (value && (isNaN(mbTrim(value)) || mbTrim(value).toString() === '-0')) {
//                 return Promise.reject(new Error(message_2));
//             } else if (minValue >= 0 && value && Number(mbTrim(value)) < minValue) {
//                 return Promise.reject(new Error(message_3));
//             } else if (value && Number(mbTrim(value)) < 0) {
//                 return Promise.reject(new Error(message_4));
//             }
//             return Promise.resolve();
//         },
//     });
// };

export const formatPhoneValidator: (
    max: number,
    min: number,
    messageMax: string,
    messageMin: string,
    messageMalformed: string,
    messageRequired: string
) => Rule = (max, min, messageMax, messageMin, messageMalformed, messageRequired) => {
    return () => ({
        validator(_, value) {
            if (value && mbTrim(value).length === 0) {
                return Promise.reject(new Error(messageRequired));
            } else if (value && (isNaN(mbTrim(value)) || value.toString().search(/[+-]/) === 0 || value.toString().includes('.'))) {
                return Promise.reject(new Error(messageMalformed));
            } else if (value && mbTrim(value).length > max) {
                return Promise.reject(new Error(messageMax));
            } else if (value && mbTrim(value).length < min) {
                return Promise.reject(new Error(messageMin));
            }
            return Promise.resolve();
        },
    });
};

export const timeDayRequired: (messageRequired: string, messageEqual: string, day: HolidayType) => Rule = (
    messageRequired,
    messageEqual,
    day
) => {
    return ({ getFieldValue }) => ({
        validator(_, value) {
            if (
                !getFieldValue(`time_in_${day}`) ||
                !getFieldValue(`minute_in_${day}`) ||
                !getFieldValue(`time_out_${day}`) ||
                !getFieldValue(`minute_out_${day}`)
            ) {
                return Promise.reject(new Error(messageRequired));
            } else if (
                getFieldValue(`time_in_${day}`) === getFieldValue(`time_out_${day}`) &&
                getFieldValue(`minute_in_${day}`) === getFieldValue(`minute_out_${day}`)
            ) {
                return Promise.reject(new Error(messageEqual));
            } else if ( getFieldValue(`time_in_${day}`) > 0 &&
                getFieldValue(`time_out_${day}`) > 0 &&
                getFieldValue(`time_in_${day}`) > getFieldValue(`time_out_${day}`)
            ) {
                return Promise.reject(new Error(FUTURE_DATE_EXCEPTION));
            } else if ( getFieldValue(`time_in_${day}`) === getFieldValue(`time_out_${day}`) &&
                getFieldValue(`minute_in_${day}`) > 0 &&
                getFieldValue(`minute_out_${day}`) > 0 && 
                getFieldValue(`minute_in_${day}`) > getFieldValue(`minute_out_${day}`)
            ) {
                return Promise.reject(new Error(FUTURE_DATE_EXCEPTION));
            }

            return Promise.resolve();
        },
    });
};

export const formatDeviceCodeValidator: (
    numberText: number,
    messageMax: string,
    messageMalformed: string,
    messageRequired: string
) => Rule = (numberText, messageMax, messageMalformed, messageRequired) => {
    return () => ({
        validator(_, value) {
            if (value && mbTrim(value).length === 0) {
                return Promise.reject(new Error(messageRequired));
            } else if (value && !regExpDeviceCode.test(mbTrim(value))) {
                return Promise.reject(new Error(messageMalformed));
            } else if (value && mbTrim(value).length > numberText) {
                return Promise.reject(new Error(messageMax));
            }
            return Promise.resolve();
        },
    });
};

export const formatTimeValidator: (message: string) => Rule = (message) => {
    return () => ({
        validator(_, value) {
            if (!idling_time_validator.includes(value)) {
                return Promise.reject(new Error(message));
            }
            return Promise.resolve();
        },
    });
};

export const sameValidator: (fieldName: NamePath, message: string) => Rule = (fieldName, message) => {
    return ({ getFieldValue }) => ({
        validator(_, value) {
            if (value && getFieldValue(fieldName) !== value) {
                return Promise.reject(new Error(message));
            }
            return Promise.resolve();
        },
    });
};

export const formatPasswordValidator: (message_1: string) => Rule = (message_1) => {
    return () => ({
        validator(_, value) {
            if (
                mbTrim(value).length == 0 ||
                value.toString().search(/(^(?=.*?[A-Za-z0-9])\S{8,50}$)/) ||
                value.toString().search(regExpPasswordCode) != -1
            ) {
                return Promise.reject(new Error(message_1));
            }
            return Promise.resolve();
        },
    });
};

export const formatNameCategoryValidator: (
    numberText: number,
    messageMax: string,
    messageMalformed: string,
    messageRequired: string
) => Rule = (numberText, messageMax, messageMalformed, messageRequired) => {
    return () => ({
        validator(_, value) {
            if (mbTrim(value).length == 0) {
                return Promise.reject(new Error(messageRequired));
            } else if (value && regExpPasswordCode.test(mbTrim(value))) {
                return Promise.reject(new Error(messageMalformed));
            } else if (value && mbTrim(value).length > numberText) {
                return Promise.reject(new Error(messageMax));
            }
            return Promise.resolve();
        },
    });
};

export const formatPlaceCodeValidator: (
    numberText: number,
    messageMax: string,
    messageMalformed: string,
    messageRequired: string
) => Rule = (numberText, messageMax, messageMalformed, messageRequired) => {
    return () => ({
        validator(_, value) {
            if (mbTrim(value).length === 0) {
                return Promise.reject(new Error(messageRequired));
            } else if (!regExpSymbol.test(mbTrim(value))) {
                return Promise.reject(new Error(messageMalformed));
            } else if (mbTrim(value).length > numberText) {
                return Promise.reject(new Error(messageMax));
            }
            return Promise.resolve();
        },
    });
};

export const formatUrlValidator: (messageMalformed: string) => Rule = (messageMalformed) => {
    return () => ({
        validator(_, value) {
            if (value && !regExpUrl.test(mbTrim(value)) && regUrlTwoByte.test(mbTrim(value))) {
                return Promise.reject(new Error(messageMalformed));
            }
            return Promise.resolve();
        },
    });
};
